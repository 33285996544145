import './index.scss';

import React from 'react';

import { Icons } from '../Icons';

import { Review } from './Review';

export const Reviews = () => (
  <div className="py-5 d-flex flex-column bg-light-grey">
    <div className="container-lg flex-column d-flex">
      <div className="align-self-center pt-3 pb-3">
        <Icons.StarIcon />
        <Icons.StarIcon />
        <Icons.StarIcon />
        <Icons.StarIcon />
        <Icons.StarIcon />
      </div>
      <h2 className="pb-2">1,000+ Satisfied Drivers</h2>
      <h4 className="pb-5">App Store Reviews</h4>
      <div className="reviews-container d-flex px-4 pb-4">
        <Review
          date="11 Oct 24"
          title="The Best TeslaCam Viewer"
          text="Perception is a must-have app for Tesla owners who review their footage on the phone. Smooth and intuitive experience for viewing footage directly on your iPhone. The interface is clean and easy to navigate, allowing you to quickly review incidents with the fast forward feature, saves time! Video playback is fast, and the app allows you to switch between different camera angles effortlessly. The ability to quickly scrub through footage and pinpoint specific events saves a lot of time. Additionally, the app supports high-quality video playback and doesn’t compromise on clarity. I also appreciated the option to export clips or save them for later viewing. Key feature is ability to decide resolution for export in grid mode and also able to select the section to crop out. Kudos to the developers, they react and work on inputs from the end users. It’s clear the developers put a lot of effort into creating an app that complements the Tesla experience. Overall, Perception is a game-changer for Tesla owners who want a fast, reliable, and user-friendly way to view Sentry Mode footage on the go. Highly recommended!"
        />
      </div>
      <div className="reviews-container d-flex px-4 pb-4">
        <Review
          date="25 Sep 24"
          title="Smooth and stunning"
          text="Awesome app, great to be able to export my cropped events"
        />
        <div className="p-2" />
        <Review
          date="23 Jan 24"
          title="Handy tool"
          text="It’s a handy tool to export saved dashcam videos (e.g. to preserve them post incident)"
        />
      </div>
      <div className="reviews-container d-flex px-4 pb-5">
        <Review
          date="16 Jul 23"
          title="Just Works Fantastically"
          text="I rarely leave reviews, but when I come across something that 'just works fantastically', I have to write a compliment to the developer. Thank you 🙏"
        />
        <div className="p-2" />
        <Review
          date="26 Jul 23"
          title="Works perfectly and so easy to use"
          text="Does exactly what it's supposed to do. Works perfectly and so easy to use. Kudos to the developer!"
        />
        <div className="p-2" />
        <Review
          date="17 Jul 23"
          title="Wonderful app"
          text="Wonderful app - the design makes the app easy to use & view various camera angles with the use of the dashboard."
        />
      </div>
    </div>
  </div>
);
