import './FAQ.scss';

import React from 'react';

import { FAQItem } from './FAQItem/FAQItem';

export const FAQ = () => (
  <div className="row d-flex justify-content-center position-relative mt-5">
    <div id="faqs" className="anchor"></div>
    <h2>FAQs</h2>
    <h3 className="h4 mt-2">Your questions, answered</h3>
    <div className="d-flex flex-column faq-container">
      <FAQItem
        question="Why should I use a TeslaCam app?"
        answer={`Rather than viewing your events one-by-one in the car, or viewing individual clips directly from the USB drive, our TeslaCam viewer app lets you view, store, trim, manage and share whole events. Perception adds a whole new layer of convenience that isn't offered elsewhere.`}
      />
      <FAQItem
        question="How is Perception better than the in-car viewer?"
        answer={`Our Sentry Mode viewer app lets you view, store, manage, and share full events from your iPhone, iPad, Android, or Mac. The in-car viewer only lets you view single events, and does not let you zoom, export, trim, or backup your events.`}
      />
      <FAQItem
        question="How do I add my TeslaCam events?"
        answer={`Find the Tesla USB drive in your glovebox. Attach the USB drive to your device (this may require an adaptor). Open Perception and tap 'Add Events'.
        Find 'TESLADRIVE' in the file picker, and then press 'Open'. Your dashcam and Sentry Mode events will then appear.`}
      />
      <FAQItem
        question="What devices are supported by Perception?"
        answer="You can use Perception on iOS, iPadOS, macOS, and Android. Perception supports iOS & iPadOS 16 and later, macOS 13 and later, and Android 10 and later."
      />
      <FAQItem
        question="What event sources are supported?"
        answer="You can add TeslaCam events from your in-car USB drive, iCloud / other cloud storage services (events needs to be downloaded first), and on-device storage on your iPhone, iPad, Mac, or Android. NAS drives are unofficially supported"
      />
      <FAQItem
        question="Is Perception compatible with TeslaUSB?"
        answer="NAS drives and TeslaUSB are unofficially supported. Whilst we haven't tested them ourselves, some of our drivers are able to use TeslaUSB. We're working to add official support in a future version. Please reach out if you'd like to see if sooner!"
      />
      <FAQItem
        question="Can I add my events to Perception wirelessly from the car?"
        answer="Unless you use a system like TeslaUSB, you will need to get the USB drive from the car, and insert it into your device. Tesla do not provide a way of viewing full dashcam or Sentry Mode events wirelessly."
      />
      <FAQItem
        question="How can I export my TeslaCam footage for my insurers?"
        answer="After adding your events to Perception you can export them, either as the original event files or as 'combined' videos, for use elsewhere. You can trim events to remove unneeded footage, and reduce export times."
      />
      <FAQItem
        question="How do I share TeslaCam events?"
        answer="Select the event, and tap the share icon at the top of the screen.
        You can choose between 'raw' (which zips the original event files) or 'combined' (for each selected angle, the video files are merged together)."
      />
      <FAQItem
        question="How can I view my Sentry Mode events on iPhone?"
        answer="Download Perception, and plug your USB drive into your iPhone to add dashcam and Sentry Mode events to Perception. You may need an adaptor."
      />
      <FAQItem
        question="How can I store my TeslaCam footage?"
        answer="After adding your dashcam and Sentry Mode footage to Perception, tap the 'store' button when viewing an event to store it on your iPhone, iPad, Android device, or Mac to view without the USB drive."
      />
      <FAQItem
        question="How is my data secured?"
        answer="All of your data stays on your devices. We do not upload any footage to the cloud, nor do we train any AI models on your footage. Your footage is yours, so it stays with you. We use anonymous analytics to improve Perception, and some data such as IP addresses / identifiers / in-app purchase data is used to manage subscriptions & prevent fraud. See our privacy policy for further details."
      />
      <FAQItem
        question="What do I do if I have an issue?"
        answer="We want to make Perception perfect, so we'd love to know if something has gone wrong.
        Reach out via this form, and we'd be happy to help - https://forms.gle/jgMHmNNBmYHkEM2f8. We typically respond in less than 24 hours."
      />
      <FAQItem
        question="How do I request a refund?"
        answer="We'd love to help you get the most out of Perception, and improve the app further. Reach out via https://peslostudios.com/contact and we'll prioritise your request, we typically respond in less than 24 hours. Alternatively, you can also reach out directly to Apple or Google for a refund."
      />
    </div>
  </div>
);
