import './PremiumBenefits.scss';

import React from 'react';

import { SVGIconOption } from '../../models/icon';

import { FeaturesLeftHalf, FeaturesRightHalf } from '../../constants/features';
import { TRIAL_LENGTH } from '../../utils/constants';

import { PremiumBenefitItem } from './PremiumBenefitItem/PremiumBenefitItem';
import { PremiumSupportItem } from './PremiumSupportItem/PremiumSupportItem';

export const PremiumBenefits = () => (
  <div>
    <div className="mb-4">
      <h2>Premium Benefits</h2>
      <h3 className="h4">{`During your ${TRIAL_LENGTH} day free trial you can access the viewer, event details and get up close.`}</h3>
    </div>

    <div className="premium-benefits-container d-flex w-100">
      <div className="premium-benefits-group group-left">
        {FeaturesLeftHalf.map(item => (
          <PremiumBenefitItem feature={item} key={item.key} />
        ))}
      </div>
      <div className="premium-benefits-group group-right">
        {FeaturesRightHalf.map(item => (
          <PremiumBenefitItem feature={item} key={item.key} />
        ))}
      </div>
    </div>

    <div className="premium-support-container mt-4 d-flex w-100">
      <PremiumSupportItem
        type={SVGIconOption.Tablet}
        title="Cross Platform"
        subTitle="Use Perception on your iPhone, iPad, Android and Mac."
      />
      <PremiumSupportItem
        type={SVGIconOption.Home}
        title="Family Sharing"
        subTitle="Use Perception with other family members on your Apple Account, for no extra cost."
      />
      <PremiumSupportItem
        type={SVGIconOption.Stars}
        title="Support Development"
        subTitle="Helps to bring new features and improvements to the app."
      />
      <PremiumSupportItem
        type={SVGIconOption.Heart}
        title="Built Together"
        subTitle="Community feedback, ideas, and requests are all encouraged."
      />
    </div>
  </div>
);
